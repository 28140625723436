<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0">{{ $t('myEvaluations') }}</p>
            <CButton size="sm" color="primary" class="px-4" @click="$store.commit('setNewEvaluationModal', true)">{{ $t('addEvaluation') }}</CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            :loading="$store.state.loadingEvaluationsList"
            striped
						column-filter
            :items="$store.state.evaluationsList"
            :fields="fields"
            :items-per-page="20"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
            <template #actions="data">
              <td>
                <CButton size="sm" color="success" class="mx-1" @click="viewEvaluation(data)">
                  <CIcon name="cil-file" size="sm"/>
                </CButton>
                <CButton size="sm" color="danger" class="mx-1" @click="deleteEvaluation(data)">
                  <CIcon name="cil-x-circle" size="sm"/>
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {deleteEvaluation} from '@/helpers/api/evaluations'
export default {
  name: 'Users',
  data () {
    return {
      fields: [
        { key: 'nombre', label: this.$i18n.t('name') },
        { key: 'apellido', label: this.$i18n.t('lastName') },
        { key: 'email', label: this.$i18n.t('email') },
        { key: 'created_at', label: this.$i18n.t('creationDate') },
        { key: 'actions', label: this.$i18n.t('actions'), filter: false }
      ],
      activePage: 1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    '$store.state.userData': {
      immediate: true,
      handler (userData) {
        if(userData && !this.$store.state.clientList){
          this.getEvaluations()
        }
      }
    }
  },
  mounted(){
    this.getEvaluations()
  },
  methods: {
    getEvaluations(){
      this.$store.dispatch('getEvaluationList')
    },
    viewEvaluation(data){
      this.$router.push({ name: 'Evaluation', params: { evaluationID: data.item.id } })
    },
    async deleteEvaluation(data){
      if(data && data.item && data.item.id){
        let completed = false
        const deleted = await deleteEvaluation({id: data.item.id})
        if(deleted.message){
          completed = true
          this.$store.dispatch('getEvaluationList')
        }
        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
          header: completed ? this.$i18n.t('deletedEvaluation'):this.$i18n.t('error'),
          message: completed ? this.$i18n.t('deletedEvaluationMessage'):this.$i18n.t('errorMessage')
        })
      }
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    }
  }
}
</script>
